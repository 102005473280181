import apiClient from "@/services/ApiClient";

export default {
  name() {
    return "auth";
  },
  login(credentials) {
    return apiClient.post("/auth/login", credentials);
  },
};
