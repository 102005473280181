import Intranet from "@/layouts/intranet/Index.vue";
import crudUpdate from "@/components/crud_ag/actions/Update";

export default {
  path: "/clients",
  component: Intranet,
  children: [
    {
      path: "",
      name: "clientsCrud",
      component: () => import("@/views/crud/ClienteCrud.vue"),
      meta: {
        auth: true,
        title: "Clients",
        serviceName: "clientes",
      },
      children: [
        {
          path: ":id",
          name: "clientsCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            title: "Client",
            serviceName: "clientes",
          },
        },
      ],
    },
  ],
};
