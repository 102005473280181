import Intranet from "@/layouts/intranet/Index.vue";
import crudUpdate from "@/components/crud_ag/actions/Update";

export default {
  path: "/properties-administrators",
  component: Intranet,
  children: [
    {
      path: "",
      name: "administratorsCrud",
      component: () => import("@/views/crud/AdministradoresCrud.vue"),
      meta: {
        auth: true,
        title: "Administradors de finques",
        serviceName: "administradores-fincas",
      },
      children: [
        {
          path: ":id",
          name: "administratorsCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "administradores-fincas",
            title: "Adminisitrador de finques",
          },
        },
      ],
    },
  ],
};
