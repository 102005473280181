import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/Vuetify";

import "material-design-icons-iconfont/dist/material-design-icons.css";

/**** PLUGINS ****/
import VuetifyConfirm from "./plugins/VuetifyConfirm";
import VJsf from "./plugins/VJsf";
import Notifications from "./plugins/VueNotification";
import Moment from "./plugins/Moment";
import VueCurrency from "./plugins/VueCurrency";
// see docs: https://github.com/xkjyeah/vue-google-maps
import VueGoogleMaps from "./plugins/VueGoogleMaps";

/**** Mixins ****/
import GlobalMixin from "./mixins/global";

import apiClient from "./services/ApiClient";
import blobApiClient from "@/services/BlobApiClient";
import Cookies from "js-cookie";

Vue.config.productionTip = false;
Vue.mixin(GlobalMixin);

const token = Cookies.get("auth_token");
apiClient.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;
blobApiClient.defaults.headers.common["Http-Authorization"] = `Bearer ${token}`;

new Vue({
  router,
  store,
  Notifications,
  vuetify,
  VuetifyConfirm,
  VJsf,
  Moment,
  VueGoogleMaps,
  VueCurrency,
  render: h => h(App),
}).$mount("#app");
