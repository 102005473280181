import { crudActions } from "@/services/CrudActions";
import apiClient from "@/services/ApiClient";

export default {
  ...crudActions("servicio-averias"),
  getCustomer(id) {
    return apiClient.get(`/servicios/${id}/info-cliente`);
  },
  changeCustomer(id, customer_id) {
    let params = { cliente_id: customer_id };
    return apiClient.post(`/servicios/${id}/cambio-cliente`, params);
  },
};
