import Intranet from "@/layouts/intranet/Index.vue";

export default {
  path: "/work-orders",
  component: Intranet,
  children: [
    {
      path: "",
      name: "workOrdersCrud",
      component: () => import("@/views/crud/ParteCrud.vue"),
      meta: {
        auth: true,
        serviceName: "partes",
        title: "Partes",
      },
      children: [
        {
          path: ":id",
          name: "workOrdersCrudUpdate",
          component: () => import("@/views/billing/work-order/Edit"),
          meta: {
            auth: true,
            serviceName: "partes",
            title: "Parte",
          },
        },
      ],
    },
  ],
};
