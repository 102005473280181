import Intranet from "@/layouts/intranet/Index.vue";

export default {
  path: "/doors",
  component: Intranet,
  children: [
    {
      path: "",
      name: "doorsCrud",
      component: () => import("@/views/crud/PuertaCrud.vue"),
      meta: {
        auth: true,
        serviceName: "puertas",
        title: "Portes",
      },
      children: [
        {
          path: ":id",
          name: "doorsCrudUpdate",
          // component: crudUpdate,
          component: () => import("@/views/services/DoorUpdate.vue"),
          meta: {
            auth: true,
            serviceName: "puertas",
            title: "Porta",
          },
        },
      ],
    },
  ],
};
