import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.min.css";
import minifyTheme from "minify-css-string";
import LRU from "lru-cache";

Vue.use(Vuetify);

const themeCache = new LRU({
  max: 10,
  maxAge: 1000 * 60 * 60, // 1 hour
});

const colors = {
  primary: "#00bba7",
  secondary: "#00CBBC",
  anchor: "#00a186",
  error: "#FF4344",
  info: "#195AE0",
  success: "#00CBBC",
  warning: "#FFBF3F",
};

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: colors,
      dark: colors,
      options: {
        minifyTheme,
        themeCache,
      },
    },
  },
  icons: {
    iconfont: "mdi",
  },
});

export default vuetify;
