<template>
  <v-app id="inspire">
    <notifications position="bottom left" width="400px" classes="prd-notification" />

    <!-- APP Nav -->
    <TheNav :navDrawer="navDrawer" @toggleMenu="toggleMenu($event)" />

    <!-- APP toolbar -->
    <TheTopBar :navDrawer="navDrawer" @toggleMenu="toggleMenu($event)" />

    <!-- APP Content -->
    <v-main :class="{ 'prd-main-full-width': !navDrawer }">
      <v-container :fluid="fluid" class="fill-height d-flex flex-column align-stretch">
        <div class="flex-shrink-1 flex-grow-0 prd-maxwidth-100">
          <breadcrumbs v-if="!$route.meta.hideBreadcrumb"></breadcrumbs>
        </div>
        <div class="flex-grow-1 flex-shrink-0 d-flex prd-maxwidth-100">
          <router-view></router-view>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    TheNav: () => import("@/layouts/intranet/components/Nav.vue"),
    TheTopBar: () => import("@/layouts/intranet/components/TopBar.vue"),
    Breadcrumbs: () => import("@/layouts/intranet/components/Breadcrumbs"),
  },
  props: {
    fluid: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    navDrawer: null,
    notification: {
      position: "bottom left",
      duration: 6000,
    },
  }),
  methods: {
    toggleMenu(value) {
      this.navDrawer = value;
    },
  },
  created() {
    //dark mode true/false
    this.$vuetify.theme.dark = this.$store.state.userSettings.darkMode;

    //font resize
    const { fontSize } = this.$store.state.userSettings;
    const html = document.getElementsByTagName("html")[0];
    if (fontSize) {
      html.style.fontSize = fontSize + "em";
    }
  },
};
</script>

<style lang="scss">
.prd-notification {
  padding: 10px;
  margin: 10px;
  font-size: 0.9em;
  color: white;
  background: #195ae0 !important;
  border-left: 5px solid #1343a7 !important;
  &.warn {
    background: #ffbf3f !important;
    border-left-color: #e39e37 !important;
  }
  &.error {
    background: #ff4344 !important;
    border-left-color: #bb2e31 !important;
  }
  &.success {
    background: #00bba7 !important;
    border-left-color: #018d7e !important;
  }
}

.v-tab {
  border-bottom: 1px #00bba7 solid;
  &--active {
    color: white !important;
    /*TODO - #00bba7 as primary color as variable, not hardcoded*/
    background-color: #00bba7;
  }
}

.prd-flex-1 {
  display: flex;
  flex: 1;
  max-width: 100%;
}
.prd-maxwidth-100 {
  max-width: 100%;
}
</style>
