import Intranet from "@/layouts/intranet/Index.vue";
import crudUpdate from "@/components/crud_ag/actions/Update";

export default {
  path: "/contacts",
  component: Intranet,
  children: [
    {
      path: "",
      name: "contactsCrud",
      component: () => import("@/views/crud/ContactoCrud.vue"),
      meta: {
        auth: true,
        title: "Contactes",
        serviceName: "clientes-contactos",
      },
      children: [
        {
          path: ":id",
          name: "contactsCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            title: "Contacte",
            serviceName: "clientes-contactos",
          },
        },
      ],
    },
  ],
};
