<template>
  <router-view :fluid="fluid"></router-view>
</template>

<script>
import ServiceFactory from "@/services/ServiceFactory";

export default {
  name: "App",
  data() {
    return {
      fluid: true,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // UPDATE title
        if (to.meta.title) {
          document.title = to.meta.title;
        }
        // UPDATE fluid view
        //this.fluid = to.meta.fluid || null

        // UPDATE Store currentService
        const service = ServiceFactory.get(to.meta.serviceName) || null;
        this.$store.dispatch("updateCurrentService", service);
      },
    },
  },
};
</script>

<style lang="scss">
/*@import "./scss/variables";*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
}
.subtitle-1,
.subtitle-2 {
  font-weight: bold !important;
}

.prd-dark-background {
  background: #121212;
}

.prd-light-background {
  background: white;
}
</style>
