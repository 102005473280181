import Intranet from "@/layouts/intranet/Index.vue";
import crudUpdate from "@/components/crud_ag/actions/Update";

export default {
  path: "/mailing-templates",
  component: Intranet,
  children: [
    {
      path: "",
      name: "mailingTemplatesCrud",
      component: () => import("@/views/crud/MailingTemplateCrud.vue"),
      meta: {
        auth: true,
        serviceName: "mailing-templates",
        title: "Plantilles de correu",
      },
      children: [
        {
          path: ":id",
          name: "mailingTemplatesCrudUpdate",
          component: crudUpdate,
          meta: {
            auth: true,
            serviceName: "mailing-templates",
            title: "Plantilla de correu",
          },
        },
      ],
    },
  ],
};
